import { Modal, AppBar, Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, Stack, Switch, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { addProductData, deleteProductData, editProductData, fetchProductData, uploadProductImageData } from '../../redux/actions/productActions';
import { fetchData } from '../../redux/actions/chargeActions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';



function Products() {

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [error , setError] = useState('');



  const [deleteProductId, setDeleteProductID] = useState(null);

  const products = useSelector((state) => state.product.products);
  const data = useSelector((state) => state.charge.data);

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState();
  const limit = 50;

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      let rsp = await dispatch(fetchProductData(page, limit, search));
      console.log("rsp", rsp)
      await dispatch(fetchData(page, limit, search));
      let total = rsp?.data?.total;
      let pages = Math.ceil(total / Number(limit));
      setTotalPages(pages);

      setLoading(false);
    };

    fetchProduct();
  }, [dispatch, page, limit, search]);

  const handlePageChange = async (event, value) => {
    await setPage(value);
  };
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  }
  const handleCloseFilter = () => {
    setOpenFilter(false);
  }

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    console.log('search step 1')
    setPage(1);
    setLoading(true);
    const searchproduct = await dispatch(fetchProductData(1, limit, search));
    console.log("search", searchproduct);
    setLoading(false);
  };
  const handleClear = async () => {
    setSearch('');
    setPage(1);
    setLoading(true);
    await dispatch(fetchProductData(1, limit, ''));
    setLoading(false);
  };

  const refreshButton = async () => {
    setLoading(true);
    await dispatch(fetchProductData(page, limit, search));
    await dispatch(fetchData(page, limit, search));
    setLoading(false);
  }


  {/*Add Product*/ }

  const [open, setOpen] = useState(false);
  const [productCode, setProductCode] = useState('');
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [priceUnit, setPriceUnit] = useState('');
  const [invertory, setInvertory] = useState('');
  const [chargeId, setChargeId] = useState('');
  const [isDimentionRequired, setIsDimentionRequired] = useState(false);
  const [primaryCharge, setPrimaryCharge] = useState('');
  const [secondaryCharge, setSecondaryCharge] = useState('');
  const [hsnCode, setHsnCode] = useState('NA');
  const closePopup = () => setOpen(false);




  const handleProductSubmit = async () => {
    const newProduct = {
      product_code: productCode, product_name: productName,
      product_description: description, price, price_unit: priceUnit, invertory,
      is_dimension_required: isDimentionRequired, ref_primary_charge_category: primaryCharge, ref_secondary_charge_category: secondaryCharge,
      hsn_code: hsnCode
    };
    await dispatch(addProductData(newProduct));
    await dispatch(fetchProductData(page, limit, search));
    closePopup();
    setProductCode('');
    setProductName('');
    setDescription('');
    setPrice();
    setPriceUnit();
    setInvertory('');
    setPrimaryCharge('');
    setSecondaryCharge('');
    setIsDimentionRequired();
  };

  {/*Edit Products*/ }

  const [editProductId, setEditProductId] = useState('');
  const [editProductName, setEditProductName] = useState('');
  const [editProductCode, setEditProductCode] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editPrice, setEditPrice] = useState('');
  const [editInvertory, setEditInvertory] = useState('');
  const [editPriceUnit, setEditPriceUnit] = useState('');
  const [editPrimaryCharge, setEditPrimaryCharge] = useState('');
  const [editSecondaryCharge, setEditSecondaryCharge] = useState('');
  const [editIsDimentionRequired, setEditIsDimentionRequired] = useState(false);
  const [editHsnCode, setEditHsnCode] = useState('NA');

  const functionOpenEditPopup = useCallback((product) => {
    setEditProductId(product.product_id);
    setEditProductName(product.product_name);
    setEditProductCode(product.product_code);
    setEditDescription(product.product_description);
    setEditPrice(product.price);
    setEditInvertory(product.invertory);
    setEditIsDimentionRequired(product.is_dimension_required);
    setEditPrimaryCharge(product?.primary_charge_details?.charge_id);
    setEditSecondaryCharge(product?.secondary_charge_details?.charge_id);
    setEditHsnCode(product?.hsn_code);
    setOpenEdit(true);
  }, []);
  const closeEditPopup = () => setOpenEdit(false);

  const editProduct = async (e) => {
    e.preventDefault();
    console.log("inside edit function")
    console.log("step2")

    const updatedProduct = {
      product_id: editProductId, product_name: editProductName,
      product_code: editProductCode, product_description: editDescription, price: editPrice,
      invertory: editInvertory, is_dimension_required: editIsDimentionRequired, ref_primary_charge_category: editPrimaryCharge,
      ref_secondary_charge_category: editSecondaryCharge, hsn_code: editHsnCode
    };
    console.log("step 3")
    await dispatch(editProductData(updatedProduct));
    await dispatch(fetchProductData(page, limit, search));
    closeEditPopup();


  };

  const hellofunction = async () => {
    try {
      await dispatch(deleteProductData(deleteProductId));
      setOpenDelete(false);
      await dispatch(fetchProductData(page, limit, search));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const functionOpenDeletePopup = useCallback((id) => {
    setDeleteProductID(id);
    setOpenDelete(true);
  }, []);

  const closeDeletePopup = () => setOpenDelete(false);

  {/*Handle Image*/ }

  const inputRef = useRef(null);
  const [image, setImage] = useState("");
  const [imgProductId, setImgProductId] = useState("");
  const [file, setFile] = useState(null);

  const handleImageChange = useCallback((event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    console.log('filename', selectedFile);
    setFile(selectedFile);
  }, []);

  const handleButtonClick = (productId) => {
    setImgProductId(productId);
    if (inputRef.current) {
      inputRef.current.click();
    } else {
      console.error("inputRef is null");
    }
  };

  useEffect(() => {
    if (file && imgProductId) {
      uploadFile(file);
    }
  }, [file, imgProductId]);

  const uploadFile = useCallback(async (data) => {
    console.log(imgProductId);
    console.log("inside", data)

    const formData = new FormData();
    const img = URL.createObjectURL(data);

    formData.append('product_image', data);
    formData.append('product_id', imgProductId);

    console.log("FormData before dispatch:", Array.from(formData.entries()));
    setImage(Array.from(formData.entries()));
    await dispatch(uploadProductImageData(formData));
  }, [imgProductId, dispatch]);

  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageOpen = (image) => {
  
  };
  const handleImageClose = () => {
    
  };

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const headingObj = {
    en: 'Product',
    hi: 'प्रोडक्ट '
  }
  const addButton = {
    en: 'Add New Product',
    hi: 'नया प्रोडक्ट जोडे'
  }
  const edit = {
    en: 'Edit',
    hi: 'संशोधित'
  }
  const view = {
    en: 'View',
    hi: 'आलोकन'
  }
  const remove = {
    en: 'Delete',
    hi: 'समाप्त'
  }
  const submit = {
    en: 'Submit',
    hi: 'निवेदन'
  }
  const cancel = {
    en: 'Cancel',
    hi: 'रद्द करें'
  }
  const update = {
    en: 'Update',
    hi: 'संशोधन'
  }
  const removeMsg = {
    hi: 'क्या आप वाकई प्रोडक्ट  को हटाना चाहते हैं',
    en: 'Are you sure you want to delete this customer'
  }
  const imagePro = {
    en: 'Change Product Image',
    hi: 'प्रोडक्ट चित्र बदलें'
  }
  const inver = {
    en: 'Invertory',
    hi: 'सामग्री'
  }
  const pricelang = {
    en: 'Price',
    hi: 'दाम'
  }
  const removePro = {
    en: 'Product Delete Confirmation',
    hi: 'प्रोडक्ट हटाने की पुष्टि'
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
          <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'flex-start', md: 'space-between' },
              width: '100%'
            }}>
              <Typography sx={{
                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                mb: { xs: 2, md: 0 },
                textAlign: { xs: 'center', md: 'left' }
              }}>
                {headingObj[language]}
              </Typography>

              <Box sx={{
                display: 'flex',
                gap: { xs: 1, md: 0.5 },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'center', md: 'flex-end' },
                width: { xs: '100%', md: 'auto' },
                alignItems: 'center'
              }}>

                <IconButton onClick={refreshButton}>
                  <RefreshIcon sx={{ fontSize: '35px' }} />
                </IconButton>
                <IconButton onClick={handleOpenFilter}>
                  <FilterListIcon sx={{ fontSize: '35px' }} />
                </IconButton>
                <Button variant="contained"
                  sx={{
                    backgroundColor: 'midnightblue',
                    textTransform: 'none',
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                    width: { xs: '100%', sm: 'auto' }
                  }}
                  onClick={() => setOpen(true)}>Add New Product</Button>
              </Box>
            </Box>
          </Toolbar>
        </Box>

        <Box maxWidth="lg">
          <Grid container rowSpacing={2} columnSpacing={2} style={{ marginTop: "2px" }}>
            {products && products.data && products.data.rows && products.data.rows.length > 0 ? (
              products.data.rows.map((product, index) => (
                <Grid item xs={12} md={12} lg={6} key={index}>
                  <Card sx={{ display: 'flex', p: 1, borderRadius: 2, height: '100%', backgroundColor: '#f8f4ff' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      {/* Image Box */}
                      <Box sx={{ width: '200px', height: 'auto', display: 'flex', alignItems: 'center' }}>
                        <CardMedia
                          component="img"
                          sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          image={product.product_image}

                          alt="Product image"
                          onClick={() => handleImageOpen(product.product_image)}
                        />

                      </Box>

                      {/* Content and Button Box */}
                      <Box sx={{ flexGrow: 1, paddingLeft: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <CardContent sx={{ padding: '0', flexGrow: 1 }}>
                          <Typography component="div" variant="h6" sx={{ fontWeight: 'bold', }}>
                            PR-{product.product_id} {product.product_code}
                          </Typography>
                          <Typography variant="subtitle1" color="text.primary" component="div" sx={{ fontSize: '18px', fontWeight: 'bold', }}>
                            {product.product_name}
                          </Typography>
                          <Typography>{product.product_description}</Typography>

                          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px', marginLeft: 8, }}>
                            <Typography sx={{ flexBasis: '50%', fontWeight: 'bold', textAlign: 'left' }}>
                              {inver[language]}
                            </Typography>
                            <Typography sx={{ flexBasis: '50%', textAlign: 'left' }}>
                              {product.invertory}
                            </Typography>
                          </Box>


                          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px', marginLeft: 8, }}>
                            <Typography sx={{ flexBasis: '50%', fontWeight: 'bold', textAlign: 'left' }}>
                              {pricelang[language]}
                            </Typography>
                            <Typography sx={{ flexBasis: '50%', textAlign: 'left' }}>
                              {product.price}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px', marginLeft: 8, gap: 1 }}>
                            <Typography sx={{ flexBasis: '50%', fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                              Dimention Req:
                            </Typography>
                            <Typography sx={{ flexBasis: '50%', textAlign: 'left' }}>
                              {product.is_dimension_required.toString()}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px', marginLeft: 8, gap: 1 }}>
                            <Typography sx={{ flexBasis: '50%', fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                              Primary Charge
                            </Typography>
                            <Typography sx={{ flexBasis: '50%', textAlign: 'left' }}>
                              {product?.primary_charge_details?.charge_name}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px', marginLeft: 8, gap: 1 }}>
                            <Typography sx={{ flexBasis: '50%', fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                              Secondary Charge
                            </Typography>
                            <Typography sx={{ flexBasis: '50%', textAlign: 'left' }}>
                              {product?.secondary_charge_details?.charge_name}
                            </Typography>
                          </Box>
                        </CardContent>

                        {/* Button Group */}
                        <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
                          <Stack direction="row" spacing={1}>
                            <Button variant="outlined" color="success" onClick={() => functionOpenEditPopup(product)} sx={{ height: '30px', minWidth: '100px', textTransform: 'none' }}>
                              Edit
                            </Button>
                            <Button variant="contained" color="error" onClick={() => functionOpenDeletePopup(product.product_id)} sx={{ height: '30px', minWidth: '100px', textTransform: 'none' }}>
                              Delete
                            </Button>
                          </Stack>
                          <Button onClick={() => handleButtonClick(product.product_id)} variant="contained" sx={{ height: '30px', minWidth: '220px', textTransform: 'none' }}>
                            Change Product Image
                          </Button>
                          <input type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }} />
                        </Stack>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography sx={{
                marginTop: 5,
                marginLeft: 5,
              }}>No Product available</Typography>
            )}
          </Grid>
        </Box>
      </Box>

      <Dialog open={open} onClose={closePopup}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{addButton[language]}</Typography>
          </DialogTitle>
          <IconButton onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">

            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              <TextField label="Product Name" onChange={e => setProductName(e.target.value)} />
              <TextField label="Product Description" onChange={e => setDescription(e.target.value)} />
            </Stack>
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              <TextField label="Product Code" onChange={e => setProductCode(e.target.value)}></TextField>
              <TextField label="Price" onChange={e => setPrice(e.target.value)} />
            </Stack>
            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16 }} >
              <TextField label="Inventory" sx={{ flexBasis: '50%', flexGrow: 1 }} onChange={e => setInvertory(e.target.value)} />
              <TextField label="Price Unit" onChange={(e) => setPriceUnit(e.target.value)} />
            </Stack>
            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16 }} >
              <FormControl sx={{ flexBasis: '50%', flexGrow: 1 }}>
                <InputLabel id="demo-simple-select-label">Primary Charge</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Primary Charge"
                  onChange={e => setPrimaryCharge(e.target.value)}
                >
                  {data && data.data && data.data.rows && data.data.rows.length > 0 ? (
                    data.data.rows.map((charge) => (

                      <MenuItem key={charge.charge_id} value={charge.charge_id} >{charge.price} | {charge.charge_name}</MenuItem>
                    ))
                  ) : (
                    <Typography variant="body1" color="initial"></Typography>
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ flexBasis: '50%', flexGrow: 1 }}>
                <InputLabel id="demo-simple-select-label">Secondary Charge</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Secondary Charge"
                  onChange={e => setSecondaryCharge(e.target.value)}
                >
                  {data && data.data && data.data.rows && data.data.rows.length > 0 ? (
                    data.data.rows.map((charge) => (

                      <MenuItem key={charge.charge_id} value={charge.charge_id} >{charge.price} | {charge.charge_name} </MenuItem>
                    ))
                  ) : (
                    <Typography variant="body1" color="initial"></Typography>
                  )}
                </Select>
              </FormControl>
            </Stack>
            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16 }} >
              <TextField label="HSN code" sx={{ flexBasis: '50%', flexGrow: 1 }} onChange={e => setHsnCode(e.target.value)} />
            </Stack>
            <Stack spacing={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography>Is Dimension Required</Typography>
              <FormControlLabel
                control={<Switch color="primary" checked={isDimentionRequired} onChange={(e) => setIsDimentionRequired(e.target.checked)} />}
              />
            </Stack>
            <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }} >
              <Button variant='outlined' sx={{ marginRight: 2, textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
              <Button variant='contained' sx={{ marginRight: 2, textTransform: 'none' }} onClick={handleProductSubmit}>Submit</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={closeEditPopup}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
              {edit[language]} {headingObj[language]}
            </Typography>
          </DialogTitle>
          <IconButton onClick={closeEditPopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">

            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              <TextField label="Product Name" value={editProductName} onChange={e => setEditProductName(e.target.value)} />
              <TextField label="Product Description" value={editDescription} onChange={e => setEditDescription(e.target.value)} />
            </Stack>

            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              <TextField label="HSN Code" value={editProductCode} onChange={e => setEditProductCode(e.target.value)} />
              <TextField label="Price" value={editPrice} onChange={e => setEditPrice(e.target.value)} />
            </Stack>

            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <TextField label="Inventory" sx={{ flexBasis: '50%', flexGrow: 1 }} value={editInvertory} onChange={e => setEditInvertory(e.target.value)} />
              <TextField label="Price Unit" value={editPriceUnit} onChange={e => setEditPriceUnit(e.target.value)} />
            </Stack>

            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <FormControl sx={{ flexBasis: '50%', flexGrow: 1 }}>
                <InputLabel id="primary-charge-select-label">Primary Charge</InputLabel>
                <Select
                  labelId="primary-charge-select-label"
                  id="primary-charge-select"
                  label="Primary Charge"
                  value={editPrimaryCharge}
                  onChange={e => setEditPrimaryCharge(e.target.value)}
                >
                  {data && data.data && data.data.rows && data.data.rows.length > 0 ? (
                    data.data.rows.map((charge) => (
                      <MenuItem key={charge.charge_id} value={charge.charge_id}>
                        {charge.price} | {charge.charge_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Typography variant="body1" color="initial"></Typography>
                  )}
                </Select>
              </FormControl>

              <FormControl sx={{ flexBasis: '50%', flexGrow: 1 }}>
                <InputLabel id="secondary-charge-select-label">Secondary Charge</InputLabel>
                <Select
                  labelId="secondary-charge-select-label"
                  id="secondary-charge-select"
                  label="Secondary Charge"
                  value={editSecondaryCharge}
                  onChange={e => setEditSecondaryCharge(e.target.value)}
                >
                  {data && data.data && data.data.rows && data.data.rows.length > 0 ? (
                    data.data.rows.map((charge) => (
                      <MenuItem key={charge.charge_id} value={charge.charge_id}>
                        {charge.price} | {charge.charge_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Typography variant="body1" color="initial"></Typography>
                  )}
                </Select>
              </FormControl>
            </Stack>
            <Stack spacing={{ display: 'flex', flexDirection: 'row', gap: 16 }} >
              <TextField label="HSN code" value={editHsnCode} sx={{ flexBasis: '50%', flexGrow: 1 }} onChange={e => setEditHsnCode(e.target.value)} />
            </Stack>

            <Stack spacing={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography>Is Dimension Required</Typography>
              <FormControlLabel
                control={<Switch color="primary" checked={editIsDimentionRequired} onChange={(e) => setEditIsDimentionRequired(e.target.checked)} />}
              />
            </Stack>

            <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
              <Button variant='outlined' sx={{ marginRight: 2, textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
              <Button variant='contained' sx={{ marginRight: 2, textTransform: 'none' }} onClick={editProduct}>Update</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>


      <Dialog open={openDelete} onClose={closeDeletePopup}>
        <DialogTitle sx={{ marginLeft: 6 }}>{removePro[language]}</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Stack spacing={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
              <Typography variant="body1" color="initial">{removeMsg[language]}</Typography>
              <Typography variant="body1" color="initial" sx={{ mb: 2 }}>"Product Name"</Typography>
              <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }} >
                <Button variant='outlined' sx={{ marginRight: 2, textTransform: 'none' }} onClick={closeDeletePopup}>Cancle</Button>
                <Button variant='contained' sx={{ marginRight: 2, backgroundColor: 'red', textTransform: 'none' }} onClick={hellofunction}>Delete</Button>
              </Stack>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5, }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      <Dialog open={imageOpen} onClose={handleImageClose} maxWidth="md">
        <DialogContent>
          {imageUrl && (
            <img src={imageUrl} alt="Product Image" style={{ width: '100%', height: 'auto' }} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={openFilter} onClose={handleCloseFilter}>
        <DialogTitle>Filter Customers</DialogTitle>
        <DialogContent>
          <TextField label="Search by product, description" fullWidth value={search}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z0-9]*$/.test(value) || value === '') {
                setSearch(value);
              } else {
                alert('Please enter only alphanumeric characters');
              }
            }}
            variant="outlined" margin="normal" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} color="primary">
            Clear
          </Button>
          <Button onClick={handleSearch} color="primary">
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Products;
