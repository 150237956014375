import { orders } from "../../api/index.js";
import * as actionTypes from "../actionTypes.js";

const initialState = {
    employee: [],
    loading: false,
    error: null,
}

const internalEmployeeReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_INTERNAL_EMPLOYEE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_INTERNAL_EMPLOYEE_SUCCESS:
            return{
                ...state,
                employee: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.FETCH_INTERNAL_EMPLOYEE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
export default internalEmployeeReducer;