export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";

export const SHOW_OTP_INPUT = 'SHOW_OTP_INPUT';
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const OTP_VERIFIED = "OTP_VERIFIED";

export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';

export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const  CREATE_DATA_REQUEST = 'CREATE_DATA_REQUEST';
export const  CREATE_DATA_SUCCESS = 'CREATE_DATA_SUCCESS';
export const  CREATE_DATA_FAILURE = 'CREATE_DATA_FAILURE';

export const  UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
export const  UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const  UPDATE_DATA_FAILURE = 'UPDATE_DATA_FAILURE';

export const  DELETE_DATA_REQUEST = 'DELETE_DATA_REQUEST';
export const  DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const  DELETE_DATA_FAILURE = 'DELETE_DATA_FAILURE';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILURE = 'EDIT_PRODUCT_FAILURE';

export const FETCH_PRODUCTLIST_REQUEST = 'FETCH_PRODUCTLIST_REQUEST';
export const FETCH_PRODUCTLIST_SUCCESS = 'FETCH_PRODUCTLIST_SUCCESS';
export const FETCH_PRODUCTLIST_FAILURE = 'FETCH_PRODUCTLIST_FAILURE';


export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const UPLOAD_PRODUCTIMG_REQUEST = 'UPLOAD_PRODUCTIMG_REQUEST';
export const UPLOAD_PRODUCTIMG_SUCCESS = 'UPLOAD_PRODUCTIMG_SUCCESS';
export const UPLOAD_PRODUCTIMG_FAILURE = 'UPLOAD_PRODUCTIMG_FAILURE';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE';

export const EDIT_ORDER_REQUEST = 'EDIT_ORDER_REQUEST';
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const ADD_ORDER_DETAILS_REQUEST = 'ADD_ORDER_DETAILS_REQUEST';
export const ADD_ORDER_DETAILS_SUCCESS = 'ADD_ORDER_DETAILS_SUCCESS';
export const ADD_ORDER_DETAILS_FAILURE = 'ADD_ORDER_DETAILS_FAILURE';

export const GET_SINGLE_ORDER_REQUEST ='GET_SINGLE_ORDER_REQUEST';
export const GET_SINGLE_ORDER_SUCCESS ='GET_SINGLE_ORDER_SUCCESS';
export const GET_SINGLE_ORDER_FAILURE ='GET_SINGLE_ORDER_FAILURE';


export const EDIT_ORDER_DETAILS_REQUEST = 'EDIT_ORDER_DETAILS_REQUEST';
export const EDIT_ORDER_DETAILS_SUCCESS = 'EDIT_ORDER_DETAILS_SUCCESS';
export const EDIT_ORDER_DETAILS_FAILURE = 'EDIT_ORDER_DETAILS_FAILURE';

export const DELETE_ORDER_DETAILS_REQUEST = 'DELETE_ORDER_DETAILS_REQUEST';
export const DELETE_ORDER_DETAILS_SUCCESS = 'DELETE_ORDER_DETAILS_SUCCESS';
export const DELETE_ORDER_DETAILS_FAILURE = 'DELETE_ORDER_DETAILS_FAILURE';

export const COMPLETED_ORDER_REQUEST ='COMPLETED_ORDER_REQUEST';
export const COMPLETED_ORDER_SUCCESS ='COMPLETED_ORDER_SUCCESS';
export const COMPLETED_ORDER_FAILURE ='COMPLETED_ORDER_FAILURE';

export const CLOSED_ORDER_REQUEST ='CLOSED_ORDER_REQUEST';
export const CLOSED_ORDER_SUCCESS ='CLOSED_ORDER_SUCCESS';
export const CLOSED_ORDER_FAILURE ='CLOSED_ORDER_FAILURE';

export const UDHAR_ORDER_REQUEST ='UDHAR_ORDER_REQUEST';
export const UDHAR_ORDER_SUCCESS = 'UDHAR_ORDER_SUCCESS';
export const UDHAR_ORDER_FAILURE = 'UDHAR_ORDER_FAILURE';

export const MARK_UDHAR_REQUEST ='MARK_UDHAR_REQUEST';
export const MARK_UDHAR_SUCCESS ='MARK_UDHAR_SUCCESS';
export const MARK_UDHAR_FAILURE ='MARK_UDHAR_FAILURE';

export const UPLOAD_ORDERIMG_REQUEST ='UPLOAD_ORDERIMG_REQUEST';
export const UPLOAD_ORDERIMG_SUCCESS ='UPLOAD_ORDERIMG_SUCCESS';
export const UPLOAD_ORDERIMG_FAILURE ='UPLOAD_ORDERIMG_FAILURE';

export const SEARCH_CUSTOMER_REQUEST ='SEARCH_CUSTOMER_REQUEST';
export const SEARCH_CUSTOMER_SUCCESS ='SEARCH_CUSTOMER_SUCCESS';
export const SEARCH_CUSTOMER_FAILURE ='SEARCH_CUSTOMER_FAILURE';

export const SEARCH_FILE_NUMBER_REQUEST = 'SEARCH_FILE_NUMBER_REQUEST';
export const SEARCH_FILE_NUMBER_SUCCESS = 'SEARCH_FILE_NUMBER_SUCCESS';
export const SEARCH_FILE_NUMBER_FAILURE = 'SEARCH_FILE_NUMBER_FAILURE';

export const SEARCH_MOBILE_NUMBER_REQUEST = 'SEARCH_MOBILE_NUMBER_REQUEST';
export const SEARCH_MOBILE_NUMBER_SUCCESS = 'SEARCH_MOBILE_NUMBER_SUCCESS';
export const SEARCH_MOBILE_NUMBER_FAILURE = 'SEARCH_MOBILE_NUMBER_FAILURE';

export const MARK_ORDER_ITEM_MISTAKE_REQUEST = 'MARK_ORDER_ITEM_MISTAKE_REQUEST';
export const MARK_ORDER_ITEM_MISTAKE_SUCCESS = 'MARK_ORDER_ITEM_MISTAKE_SUCCESS';
export const MARK_ORDER_ITEM_MISTAKE_FAILURE = 'MARK_ORDER_ITEM_MISTAKE_FAILURE';

export const FETCH_ORDER_COUNT_REQUEST = 'FETCH_ORDER_COUNT_REQUEST';
export const FETCH_ORDER_COUNT_SUCCESS = 'FETCH_ORDER_COUNT_SUCCESS';
export const FETCH_ORDER_COUNT_FAILURE = 'FETCH_ORDER_COUNT_FAILURE';

export const CREATE_MULTIPLE_ITEMS_REQUEST = 'CREATE_MULTIPLE_ITEMS_REQUEST';
export const CREATE_MULTIPLE_ITEMS_SUCCESS = 'CREATE_MULTIPLE_ITEMS_SUCCESS';
export const CREATE_MULTIPLE_ITEMS_FAILURE = 'CREATE_MULTIPLE_ITEMS_FAILURE';

export const EDIT_MULTIPLE_ITEMS_REQUEST = 'EDIT_MULTIPLE_ITEMS_REQUEST';
export const EDIT_MULTIPLE_ITEMS_SUCCESS = 'EDIT_MULTIPLE_ITEMS_SUCCESS';
export const EDIT_MULTIPLE_ITEMS_FAILURE = 'EDIT_MULTIPLE_ITEMS_FAILURE';

export const STATUS_CHANGE_REQUEST = 'STATUS_CHANGE_REQUEST';
export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS';
export const STATUS_CHANGE_FAILURE = 'STATUS_CHANGE_FAILURE';

export const FETCH_INTERNAL_EMPLOYEE_REQUEST = 'FETCH_INTERNAL_EMPLOYEE_REQUEST';
export const FETCH_INTERNAL_EMPLOYEE_SUCCESS = 'FETCH_INTERNAL_EMPLOYEE_SUCCESS';
export const FETCH_INTERNAL_EMPLOYEE_FAILURE = 'FETCH_INTERNAL_EMPLOYEE_FAILURE';










