import React, { useEffect, useState } from 'react';
import { Avatar, Box, Container, IconButton, Menu, MenuItem, Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { LOG_OUT, SHOW_OTP_INPUT } from '../../redux/actionTypes';
import { useNavigate } from 'react-router-dom';
import { fetchUserDataSuccess, fetchUserDetailsSuccess, LogIn } from '../../redux/actions/authActions';
import { verifyOtp } from '../../api';

const ProfileMenu = ({ open, anchor, profile, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const handleLogout = () => {
    setOpenDialog(true);
  };

  const confirmLogout = async () => {
    await dispatch({ type: LOG_OUT, payload: {navigate, setLoading } });
    setOpenDialog(false);
  };

  const cancelLogout = () => {
    setOpenDialog(false);
  };


  
  useEffect(() => {
    const userData = async () => {
      setLoading(true);
      await dispatch(fetchUserDataSuccess());
      await dispatch(fetchUserDetailsSuccess());
      setLoading(false);
    };
    userData();
  }, [dispatch]);
  const data = useSelector((state) => state.auth.data);

  const userInfo = localStorage.getItem("user");
  var user;
  if (userInfo == null) {
    user = {}
  } else {
    user = JSON.parse(userInfo || {})
  }

  const getInitials = (fullName) => {
    if(!fullName) return '';
    return fullName
      .split(' ')
      .map(word => word[0])
      .join('');
  };

  return (
    <>
      <Menu open={open} anchorEl={anchor} onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        sx={{}}>
        {profile && (
          <Box sx={{ margin: "-10px 0px 10px 0px", padding: "15px", borderRadius: "12px 12px 0px 0px", backgroundColor: "#e2f2f9" }}>
            <Box>
              <Typography variant='subtitle1'>{profile.FirstName || ""}&nbsp;{profile.LastName || ""}</Typography>
            </Box>
            <Typography variant='body1'>{profile.PrimaryEmail || ""}&nbsp;</Typography>
          </Box>
        )}
        <Container maxWidth="md" sx={{ p: 1, backgroundColor: '#f0f0f0' }}>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="column" useFlexGap flexWrap="wrap">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Typography variant='text' sx={{ fontSize: '0.8rem' }}>{user.username}</Typography>
              </Typography>
              <IconButton sx={{ textAlign: 'center' }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
              <Avatar  sx={{ backgroundColor: "#004182", width: 85, height: 85 }} src={user.avatar ? user.avatar : null}>
                {!user.avatar && getInitials(user.fullName)}
              </Avatar>
              <Typography variant="body1" color="initial">Hi, {user.fullName}</Typography>
              <Typography sx={{ fontSize: '13px', borderRadius: 5, border: '1px solid', borderColor: 'black', paddingLeft: '10px', paddingRight: '10px', 'paddingTop': '1px', paddingBottom: '1px', color: 'blue' }}>Manage your Awesome Innovator account</Typography>
            </Box>
            <Box sx={{ display: 'flex', borderRadius: 5, border: '1px solid', borderColor: 'black', justifyContent: 'center', backgroundColor: 'lightgray' }}>
              <MenuItem className='flexCenter_Row' sx={{ gap: "15px" }} onClick={() => { navigate("/profile"); onClose(); }}>
                <AccountCircleOutlinedIcon />
                <Typography sx={{ marginRight: "20px", }}>Profile</Typography>
              </MenuItem>
              <MenuItem className='flexCenter_Row' sx={{ gap: "15px" }} onClick={handleLogout}>
                <LogoutIcon />
                <Typography sx={{ marginRight: "20px" }}>Logout</Typography>
              </MenuItem>
            </Box>
          </Stack>
        </Container>
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={cancelLogout}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>Are you sure you want to log out?</DialogContent>
        <DialogActions>
          <Button onClick={cancelLogout} color="primary">Cancel</Button>
          <Button onClick={confirmLogout} color="secondary">Logout</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProfileMenu;