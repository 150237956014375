import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchInternalEmployeeRequest = () => ({
    type: actionTypes.FETCH_INTERNAL_EMPLOYEE_REQUEST,
});
export const fetchInternalEmployeeSuccess = (data) => ({
    type: actionTypes.FETCH_INTERNAL_EMPLOYEE_SUCCESS,
    payload: data
});
export const fetchInternalEmployeeFailure = (error) => ({
    type: actionTypes.FETCH_INTERNAL_EMPLOYEE_FAILURE,
    payload: error,
});

export const fetchInternalEmployeeData = () => {
    return async (dispatch) => {
        dispatch(fetchInternalEmployeeRequest());
        try {
            const { data } = await api.getInternalEmployee();
            dispatch(fetchInternalEmployeeSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchInternalEmployeeFailure(error));
        }
    };

};