import { AppBar, Avatar, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { searchByFileNumberData, searchByMobileNumberData } from '../../redux/actions/searchOrderActions';
import { markOrderItemMistakeData } from '../../redux/actions/orderDetailsActions';
import { statusChangeData } from '../../redux/actions/statusChangeActions';


const SearchOrder = () => {

  const searchOrders = useSelector((state) => state.searchOrder.searchOrders);
  console.log("Search Orders", searchOrders);
  const customers = useSelector((state) => state.customer.customers);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  {/*Search By file Number*/ }
  const navigate = useNavigate();
  const [fileNumber, setFileNumber] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [fetchedData, setFetchedData] = useState({});
  const [mobileNumber, setMobileNumber] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [noResults, setNoResults] = useState(false);


  const handleSearchByFileNumber = async () => {
    if (fileNumber.trim() !== '') {
      if (fetchedData[fileNumber]) {
        setOrderData(fetchedData[fileNumber]);
      } else {
        const response = await dispatch(searchByFileNumberData(fileNumber));
        if (!response.data || response?.data?.rows?.length === 0) {
          setNoResults(true);
        } else {
          setNoResults(false);
        }
      }
    } else {

    }
  };
  const handleSearchByMobileNumber = async () => {
    if (mobileNumber.trim() !== '') {
      const response = await dispatch(searchByMobileNumberData(mobileNumber));
      if (!response?.data || response?.data?.rows?.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  };

  const handleOpenDialog = (order, item) => {
    setSelectedOrder(order);
    setCurrentItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOrder(null);
    setCurrentItem(null);
  };
  const handleConfirmMistake = async () => {
    console.log("inside mistake");
    if (selectedOrder && currentItem) {
      const response = await dispatch(markOrderItemMistakeData(selectedOrder.order_id, currentItem.item_id));
      console.log("response", response);
      setOpenDialog(false);
    }
  };

  const handleCloseAllItems = async (newStage, order) => {
    setSelectedOrder(order);
    if (selectedOrder && selectedOrder.order_items) {
      for (let item of selectedOrder.order_items) {
        const newStages = { stage: newStage, item_id: item.item_id, order_id: selectedOrder.order_id }
        await dispatch(statusChangeData(newStages));
      }
      setOpenDialog(false);
    }
  };


  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>

          <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'flex-start', md: 'space-between' },
              width: '100%'
            }}>
              <Typography sx={{
                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                mb: { xs: 2, md: 0 },
                textAlign: { xs: 'center', md: 'left' }
              }}>Search orders</Typography>
              <Box sx={{
                display: 'flex',
                gap: { xs: 1, md: 0.5 },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'center', md: 'flex-end' },
                width: { xs: '100%', md: 'auto' },
                alignItems: 'center'
              }} />
              <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'row' },
                justifyContent: { xs: 'center' }
              }}>
                <RefreshIcon sx={{ fontSize: { xs: '25px', sm: '30px', md: '35px' } }} />
              </Box>
            </Box>
          </Toolbar>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 2
        }}>
          <Stack spacing={{ flexBasis: '50%', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
            <TextField label="Mobile Number" fullWidth sx={{ marginBottom: 2 }} value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
            <Button variant='contained' sx={{
              textTransform: 'none',
              fontSize: { xs: '0.9rem', sm: '1rem' },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mt: 1,
              backgroundColor: 'green'
            }}
              onClick={handleSearchByMobileNumber}>
              Search By Mobile Number
            </Button>
          </Stack>
          <Stack spacing={{ flexBasis: '50%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <TextField label="File Number" fullWidth sx={{
              marginBottom: 2,
            }} value={fileNumber} onChange={(e) => setFileNumber(e.target.value)} />
            <Button variant='contained' sx={{
              textTransform: 'none',
              fontSize: { xs: '0.9rem', sm: '1rem' },
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mt: 1,
            }}
              onClick={handleSearchByFileNumber}>Search By File Number</Button>
          </Stack>
        </Box>
      </Box>
      {/* Display a message if no results are found */}
      {noResults && (
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Typography variant='h6'>Nothing is there</Typography>
        </Box>
      )}

      <Grid container spacing={2} style={{ marginTop: "2px" }}>
        {searchOrders && searchOrders.data && searchOrders.data.data && searchOrders.data.data.rows &&
          searchOrders.data.data.rows.map((order, index) => (
            <Grid item xs={12} md={12} key={index}>
              <Card sx={{ display: 'flex', flexDirection: 'column', p: 1, borderRadius: 2 }}>
                <Grid container spacing={6}>

                  <Grid item xs={12} md={1.5}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                      <IconButton>
                        <Avatar sx={{ backgroundColor: "#004182", width: '100px', height: '100px' }}>
                          <Typography color="white">{order?.file_number || 'NA'}</Typography>
                        </Avatar>
                      </IconButton>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: 1 }}>
                        <Typography variant='caption'>Status - {order.stage}</Typography>
                      </Box>
                    </Box>

                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: 1 }}>
                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>First Employee: </span> {order?.alloted_employee_details?.fullName}
                      </Typography>
                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>Second Employee: </span>  {order?.alloted_second_employee_details?.fullName}
                      </Typography>
                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>Completion Date: </span>
                        {new Date(order?.completion_date).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit"
                        })}
                      </Typography>


                      <Typography color="initial" sx={{ fontSize: '0.825rem' }}>
                        <span style={{ color: '#616161' }}>Created at: </span>
                        {new Date(order?.createdAt).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit"
                        })}{" "}
                        {new Date(order?.createdAt).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true
                        })}
                      </Typography>

                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>Customer Name: </span>  {order?.associated_customer?.name}
                      </Typography>
                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>Mobile Number: </span>  {order?.associated_customer?.mobile}
                      </Typography>
                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>Address: </span>  {order?.associated_customer?.address}
                      </Typography>
                      <Typography variant="body1" color="initial">
                        <span style={{ color: '#616161' }}>Order #: </span>  {order?.order_id}
                      </Typography>

                    </Box>


                  </Grid>
                  <Grid item xs={12} md={7.5}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      {order.order_items && order.order_items.length > 0 ? (
                        order.order_items.map((item, idx) => (
                          <Box key={idx} sx={{ display: 'flex', flexDirection: 'column', margin: '2px', padding: '10px', borderRadius: '10px', border: '1px solid lightgray' }}>
                            <Box sx={{ width: '50px', height: '50px', marginRight: 1, marginBottom: 1, fontSize: '0.425rem' }}>
                              <img src={item.final_image || 'placeholder.png'} alt={`Item Image not found`} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography variant='caption' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', }}>{item?.associated_order_product?.product_name}</Typography>
                              <Typography variant='caption'>Qty: {item.quantity}</Typography>
                            </Box>
                            <Button variant='contained' sx={{ height: 20, textTransform: 'none', backgroundColor: 'red', marginTop: 1, maxWidth: '120px' }} onClick={() => handleOpenDialog(order, item)}>Mistake</Button>
                          </Box>
                        ))
                      ) : (
                        <Box sx={{ width: '100px', height: '100px', marginRight: 1, marginBottom: 1, backgroundColor: '#f0f0f0' }}>
                          {/* Placeholder for missing images */}
                        </Box>
                      )}
                    </Box>
                  </Grid>



                </Grid>

                <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, justifyContent: 'center', marginTop: 2 }}>
                  <Button variant="outlined" sx={{ height: 20, textTransform: 'none', color: 'green' }} onClick={() => handleCloseAllItems("completed_and_closed", order)}>Mark Complete & Closed - Picked up by Customer</Button>
                </Stack>

              </Card>
            </Grid>
          ))}
      </Grid>

      {/* Dialog for marking item as a mistake */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Mistake</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to mark this item as a mistake?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmMistake} color="secondary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchOrder;