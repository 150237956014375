import axios from "axios";

const API = axios.create({
    baseURL: `https://account.rajoffset.com/`,
    // baseURL: `http://localhost:3000/`,
});

API.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
        console.log("LocalStorage",localStorage.getItem("access_token"));
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    }
    return req;
});

API.interceptors.response.use((response) => {

    return response;
}, (error) => {
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
})

export const login = (form) => API.post(`internal-api-v2/auth/login/`, form);
export const verifyOtp = (form) => API.post(`internal-api-v2/auth/login-verify-otp/`, form);

export const charges = (page, limit, search) => API.get(`crm/charge-category/v1/?page=${page}&limit=${limit}&search=${search}`);
export const createCharges = (form) => API.post(`crm/charge-category/v1/`,form);
export const updateCharge = (id,form) => API.put(`crm/charge-category/v1/${id}`,form);
export const deleteCharge = (id) => API.delete(`crm/charge-category/v1/${id}`);

export const customers = (page, limit, search) => API.get(`crm/customer/v1/?page=${page}&limit=${limit}&search=${search}`);
export const addCustomer = (form) => API.post(`crm/customer/v1/`,form);
export const editCustomer = (id,form) => API.put(`crm/customer/v1/${id}`,form);
export const deleteCustomer = (id) => API.delete(`crm/customer/v1/${id}`);

export const products = (page,limit, search) => API.get(`crm/product/v1/?page=${page}&limit=${limit}&search=${search}`);
export const addProduct = (form) => API.post(`crm/product/v1/`,form);
export const editProduct = (id, form) => API.put(`crm/product/v1/${id}`,form);
export const deleteProduct = (id) => API.delete(`crm/product/v1/${id}`);
export const productImage = (id,form) => API.put(`crm/product-image-upload/v1/${id}`,form);
export const productList = (form) => API.get(`crm/product-list/v1/`, form);

export const orders = (page, limit,stage,search) => API.get(`crm/order/v1/?page=${page}&limit=${limit}&stage=${stage}&search=${search}`);
export const addOrder = (form) => API.post(`crm/order/v1/`,form);
export const editOrder = (id, form) => API.put(`crm/order/v1/${id}`,form);
export const deleteOrder = (id) => API.delete(`crm/order/v1/${id}`);
export const imageUpload = (orderId,itemId,form) => API.put(`crm/order-item-image-upload/v1/${orderId}/${itemId}`,form);
export const getSingleOrder = (id, form) => API.get(`crm/order/v1/${id}`,form);


export const addOrderDetails = (id,form) => API.post(`crm/order-item/v1/${id}`,form);
export const editOrderDetails = (orderId,itemId, form) => API.put(`crm/order-item/v1/${orderId}/${itemId}`,form);
export const deleteOrderDetails = (orderId,itemId) => API.delete(`crm/order-item/v1/${orderId}/${itemId}`);

export const completedorder = (page, limit) => API.get(`crm/completed-order/v1/?page=${page}&limit=${limit}`);

export const closedOrder = (page, limit, search) => API.get(`crm/closed-order/v1/?page=${page}&limit=${limit}&search=${search}`);

export const udharOrder =(page, limit, search) => API.get(`/crm/udhar/v1/?page=${page}&limit=${limit}&search=${search}`);
export const markUdhar = (orderId,form) => API.post(`/crm/mark-udhar/v1/${orderId}`,form);

export const searchCustomer = (mobileNum) => API.get(`/crm/customer-exists/v1/${mobileNum}`);

export const searchOrderByFileNumber = (fileNumber, form) => API.get(`/crm/order/v1/?search=${fileNumber}`, form);
export const searchOrderByMobileNumber = (mobileNumber, form) => API.get(`/crm/order-search-by-mobile/v1/?search=${mobileNumber}`,form);

export const markOrderItemMistake = (orderId, itemId) => API.post(`/crm/order-item-mistake-mark/v1/${orderId}/${itemId}`);

export const orderCount = (form) => API.get(`/crm/dashboard/v1/order-count`, form);

export const createMultipleItems =(orderId, form) => API.post(`/crm/multi-order-item/v1/${orderId}`, form);
export const editMultipleItems = (orderId, form) => API.put(`/crm/order-combined-update/v1/${orderId}`,form);

export const statusChange = (orderId, itemId, form) => API.put(`/crm/order-item-status-change/v1/${orderId}/${itemId}`,form);

export const markMistakeOnOrderApiFn = (orderId, form) => API.put(`/crm/order-mistake-mark/v1/${orderId}/`,form);

export const triggerNotificationOnOrderApiFn = (orderId, form) => API.post(`/crm/order-trigger-notification/v1/${orderId}/`,form);

export const getInternalEmployee = () => API.get(`/crm/employee-list/v1/`);



