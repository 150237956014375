import { AppBar, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography, Tooltip, TableContainer, Table, TableHead, TableCell, Paper, TableBody, TableRow, Menu, ListItemIcon, Chip } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { useDispatch, useSelector } from 'react-redux';
import { addOrderData, deleteOrderData, editOrderData, fetchOrderData, uploadOrderImage } from '../../redux/actions/orderActions';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { statusChangeData } from '../../redux/actions/statusChangeActions';
import NoDataImage from "../../assets/images/no_data.jpg";
import { Pagination } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';


const OrderPage = () => {
  {/*Fetch Operation*/ }
  const orders = useSelector((state) => state.order.orders);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false); // State to trigger refresh

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 250;
  const [stage, setStage] = useState('pending');



  useEffect(() => {
    fetchOrderData(page, limit);
  }, [page]);

  const handlePageChange = async (event, value) => {
    console.log("value", value);
    await setPage(value);
    let rsp = await dispatch(fetchOrderData(value, limit, "pending", ""));
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      let rsp = await dispatch(fetchOrderData(page, limit, "pending", ""));
      console.log("rsp", rsp);
      let total = rsp?.data?.total;
      let pages = Math.ceil(total / Number(limit));
      setTotalPages(pages);
      // await dispatch(fetchCustomerData());
      setLoading(false);
    };
    fetchOrder();
  }, [dispatch]);


  const refreshButton = async () => {
    setLoading(true);
    setRefresh((prev) => !prev); // Toggle refresh state to trigger re-render
    let rsp = await dispatch(fetchOrderData(page, limit, stage, ""));
    console.log("rsp", rsp);
    let total = rsp?.data?.total;
    let pages = Math.ceil(total / Number(limit));
    setTotalPages(pages);
    setLoading(false);
  }

  {/*Edit functionality*/ }
  {/*Delete Functionality*/ }
  const [deleteOrderId, setDeleteOrderId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const closeDeletePopup = () => setOpenDelete(false);
  const deleteOrderPopup = useCallback((id) => {
    setDeleteOrderId(id);
    setOpenDelete(true);
  }, []);

  const deleteOrderFn = async () => {
    try {
      await dispatch(deleteOrderData(deleteOrderId));
      setOpenDelete(false);
      await dispatch(fetchOrderData());
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  {/*Handling customer Data*/ }

  const [newCustomer, setNewCustomer] = useState('');
  const [district, setDistrict] = useState('');
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();



  {/*Action Popup*/ }
  const [openAction, setOpenAction] = useState(false);
  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [imageItemId, setImageItemId] = useState('');
  const [imageOrderId, setImageOrderId] = useState('');

  const closeAction = () => setOpenAction(false);


  const handleAction = (orderid, item) => {
    setSelectedOrderItem(item);
    setImageItemId(item.item_id);
    console.log('item id', imageItemId);
    setImageOrderId(orderid);
    console.log('order id', imageOrderId);
    setOpenAction(true);

  };

  {/*Handle Image*/ }
  const inputRef = useRef(null);
  const [url, setUrl] = useState('');


  const handleImageUploadClick = (event) => {
    const file = event.target.files[0];
    console.log('file', file);
    setUrl(URL.createObjectURL(file));
    uploadImage(file);
  }

  const handleImageButtonClick = () => {
    inputRef.current.click();
  }

  const uploadImage = async (data) => {

    console.log("inside upload Image", data)
    const formData = new FormData();
    formData.append('item_id', imageItemId);
    formData.append('order_id', imageOrderId);
    formData.append('final_image', data);

    console.log("FormData before dispatch:", Array.from(formData.entries()));

    await dispatch(uploadOrderImage(formData));
  }



  const handleUpdateStage = async (newStage, itemId) => {
    const newStages = { stage: newStage, item_id: itemId, order_id: imageOrderId }
    console.log("stage", newStages);
    await dispatch(statusChangeData(newStages));

    closeAction();
    refreshButton();
  }
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });
  const headingObj = {
    en: 'Orders',
    hi: 'ऑर्डर सूची'
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const formatTimeAndDate = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const time = date.toLocaleTimeString();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year} ${time.split(':').slice(0, 2).join(':') + ' ' + time.split(' ')[1]
      }`;
  }
  // handle Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [editOrderId, setEditOrderId] = useState('');

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setEditOrderId(id);
    setDeleteOrderId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setEditOrderId(null);
    setDeleteOrderId(null);
  };
  // handle stage data 
  const [selectedChip, setSelectedChip] = useState("pending");

  useEffect(() => {

  }, [selectedChip]);

  const handleChipClick = async (stage) => {
    setSelectedChip(stage);
    setLoading(true);
    await dispatch(fetchOrderData(page, limit, stage, ""));
    setLoading(false);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
          <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'flex-start', md: 'space-between' },
              width: '100%'
            }}>
              <Typography sx={{
                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                mb: { xs: 2, md: 0 },
                textAlign: { xs: 'center', md: 'left' }
              }}>
                {headingObj[language]}
              </Typography>

              <Box sx={{
                display: 'flex',
                gap: { xs: 1, md: 0.5 },
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'center', md: 'flex-end' },
                width: { xs: '100%', md: 'auto' },
                alignItems: 'center'
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', md: 'row' },
                  justifyContent: { xs: 'flex-start' }
                }}>
                  <IconButton onClick={refreshButton}>
                    <RefreshIcon sx={{ fontSize: { xs: '25px', sm: '30px', md: '35px' } }} />
                  </IconButton>
                </Box>

                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: 'midnightblue',
                    textTransform: 'none',
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                    width: { xs: '100%', sm: 'auto' }
                  }}
                  onClick={() => navigate('/order/create')}
                >
                  Add New Order
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
              <Chip
                label="order_placed"
                onClick={() => handleChipClick("order_placed")}
                variant={selectedChip === "order_placed" ? 'filled' : 'outlined'}
              />
              <Chip label="pending" onClick={() => handleChipClick("pending")} variant={selectedChip === "pending" ? 'filled' : 'outlined'} />
              <Chip label="hold" onClick={() => handleChipClick("hold")} variant={selectedChip === "hold" ? 'filled' : 'outlined'} />
              <Chip label="design_ready" onClick={() => handleChipClick("design_ready")} variant={selectedChip === "design_ready" ? 'filled' : 'outlined'} />
              <Chip label="completed" onClick={() => handleChipClick("completed")} variant={selectedChip === "completed" ? 'filled' : 'outlined'} />
              <Chip label="closed" onClick={() => handleChipClick("closed")} variant={selectedChip === "closed" ? 'filled' : 'outlined'} />
              <Chip label="mistake" onClick={() => handleChipClick("mistake")} variant={selectedChip === "mistake" ? 'filled' : 'outlined'} />
            </Box>
          </Toolbar>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ marginTop: '2px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="order table">
              <TableHead>
                <TableRow>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Date | File Number</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Order Items</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders && orders.data && orders.data.rows && orders.data.rows.length > 0 ? (
                  orders.data.rows.map((order, index) => (
                    <TableRow key={index}>
                      {/* Order Details */}
                      <TableCell>
                        #{order.order_id}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={"Order added on " + formatTimeAndDate(order.createdAt) || order.order_id}>
                          <div>
                            <Typography variant="body2" color="initial">
                              {order.completion_date ? formatDate(order.completion_date) : 'N/A'} | File Number: {order?.file_number}
                            </Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                              <Typography variant="caption" color="initial">
                                <span style={{ color: '#616161' }}>First Employee: </span> {order?.alloted_employee_details?.fullName}
                              </Typography>
                              <Typography variant="caption" color="initial">
                                <span style={{ color: '#616161' }}>Second Employee: </span>  {order?.alloted_second_employee_details?.fullName}
                              </Typography>
                            </Box>

                          </div>
                        </Tooltip>
                      </TableCell>


                      {/* Customer Name and Phone */}
                      <TableCell>
                        <Typography >{order.associated_customer?.name}</Typography>
                        <IconButton sx={{ padding: 0 }}>
                          <LocalPhoneRoundedIcon fontSize="small" />
                          <Typography >
                            {order.associated_customer?.mobile}
                          </Typography>
                        </IconButton>
                      </TableCell>


                      {/* Order Items */}
                      <TableCell>
                        {order.order_items && order.order_items.map((item, index) => (
                          <Grid
                            container
                            key={index}

                          >
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant="caption"
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  textDecoration: (item.stage === 'design_ready' || item.stage === 'completed') ? 'line-through' : 'none'
                                }}
                              >
                                {item.associated_order_product?.product_name || "Product's name is not"}
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={2}>
                              <Typography variant="caption">
                                Qty: {item.quantity}
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                              <Typography
                                variant="caption"
                                sx={{ color: item.display_msg.includes('late') ? 'red' : 'green' }}
                              >
                                {item.display_msg.split(':')[1]?.trim()} ({item.stage})
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Button
                                variant="contained"
                                sx={{ height: 20, textTransform: 'none' }}
                                onClick={() => handleAction(order.order_id, item)}
                              >
                                Action
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                      </TableCell>



                      {/* Actions */}
                      <TableCell>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuClick(event, order?.order_id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={menuOpen && editOrderId === order?.order_id && deleteOrderId === order?.order_id}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => navigate(`edit/${order.order_id}`)}>
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            Edit
                          </MenuItem>
                          <MenuItem onClick={() => navigate(`view/${order.order_id}`)}>
                            <ListItemIcon>
                              <VisibilityIcon />
                            </ListItemIcon>
                            View
                          </MenuItem>
                          <MenuItem onClick={() => deleteOrderPopup(order.order_id)} sx={{
                            color: 'red'
                          }}>
                            <ListItemIcon sx={{
                              color: 'red'
                            }}>
                              <DeleteIcon />
                            </ListItemIcon>
                            Delete
                          </MenuItem>
                          {/* <MenuItem onClick={() => handleAction(order.order_id, item)}>
                                  <ListItemIcon>
                                    <FormatListBulletedIcon />
                                  </ListItemIcon>
                                  Action
                                </MenuItem> */}

                        </Menu>

                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box sx={{ width: '100%', textAlign: 'center', padding: '20px' }}>
                        <Box component="img" src={NoDataImage} alt="No data" sx={{ width: '300px', height: '300px', objectFit: 'fill' }} />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </Box>


      <Dialog open={openDelete} onClose={closeDeletePopup}>
        <DialogTitle sx={{ marginLeft: 6 }}>Product Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Stack spacing={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
              <Typography variant="body1" color="initial">Are you sure you want to delete Order</Typography>
              <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }} >
                <Button variant='outlined' sx={{ marginRight: 2 }} onClick={closeDeletePopup}>Cancel</Button>
                <Button variant='contained' sx={{ marginRight: 2, backgroundColor: 'red' }} onClick={deleteOrderFn}>Delete</Button>
              </Stack>
            </Stack>
          </DialogActions>
        </DialogContent>
      </Dialog>


      {/*Action Popup*/}
      <Dialog open={openAction} onClose={closeAction} >

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Typography variant='h6' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Order Item Action</Typography>
          </DialogTitle>
          <IconButton onClick={closeAction} >

          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
              {selectedOrderItem && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography>Your Current Order Item </Typography>
                  <Typography>
                    <Typography component="span" sx={{ color: 'purple', marginRight: 1, fontWeight: 'bold' }}>{selectedOrderItem && selectedOrderItem.associated_order_product && selectedOrderItem.associated_order_product.product_name}
                    </Typography>
                    is
                  </Typography>
                  <Typography variant='h5' sx={{ color: 'green' }}>{selectedOrderItem.stage}</Typography>
                </Box>
              )}

              <div onClick={handleImageButtonClick}>
                <Button variant='contained' sx={{ width: '400px', height: '30px', fontSize: '12px' }}>Upload Final Image</Button>
                <input type="file" ref={inputRef} onChange={handleImageUploadClick} style={{ display: "none" }} />
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            {selectedOrderItem && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>

                <Typography>-------------- </Typography>


                <Button
                  variant='outlined'
                  sx={{ width: '400px', height: '30px', fontSize: '0.625rem', marginLeft: 1, color: 'black' }}
                  onClick={() => handleUpdateStage("design_ready", selectedOrderItem.item_id)}
                >
                  Mark as Design Ready (ऑफसेट प्रिंटिंग या कार्ड के लिए "डिजाइन तैयार")
                </Button>

                <Button
                  variant='contained'
                  sx={{ width: '400px', height: '30px', fontSize: '0.625rem', marginLeft: 1, backgroundColor: 'green' }}
                  onClick={() => handleUpdateStage("completed", selectedOrderItem.item_id)}
                >
                  Mark Order as Completed (ऑर्डर पूरा)
                </Button>

                <Button
                  variant='outlined'
                  sx={{ width: '400px', height: '30px', fontSize: '0.625rem', marginLeft: 1, color: 'red' }}
                  onClick={() => handleUpdateStage("hold", selectedOrderItem.item_id)}
                >
                  Mark Order as HOLD
                </Button>

                <Typography>-------------- </Typography>

                <Button
                  variant='outlined'
                  sx={{ width: '400px', height: '30px', fontSize: '0.625rem', marginLeft: 1, color: 'gray' }}
                  onClick={() => handleUpdateStage("closed", selectedOrderItem.item_id)}
                >
                  Directly Mark Order as CLOSED ("ऑर्डर बंद" - ग्राहक द्वारा ले लिया गया)
                </Button>
              </Box>
            )}
          </DialogActions>
        </Box>



      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5, }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default OrderPage;