import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import AuthReducer from "./authReducers";
import chargeReducer from "./chargeReducers";
import customerReducer from "./customerReducers";
import productReducer from "./productReducers";
import orderReducer from "./orderReducers";
import orderDetailsReduces from "./orderDetailsReducers";
import completedorderReducer from "./completedOrderReducers";
import closedOrderReducer from "./closedOrderReducers";
import udharOrderReducer from "./udharorderReducers";
import searchOrderReducer from "./searchOrderReducers";
import orderCountReducer from "./orderCountReducers";
import statusChangeReducer from "./statusChangeReducers";
import internalEmployeeReducer from "./internalEmployeeReducer";

const persistConfig = {
    key: "root",
    storage,
};


const rootReducer = combineReducers({
    auth: AuthReducer,
    charge: chargeReducer,
    customer: customerReducer,
    product: productReducer,
    order: orderReducer,
    orderDetails: orderDetailsReduces,
    completedOrder: completedorderReducer,
    closedOrder: closedOrderReducer,
    udharOrder: udharOrderReducer,
    searchOrder: searchOrderReducer,
    orderCount: orderCountReducer,
    statusChange: statusChangeReducer,
    internalEmployee: internalEmployeeReducer,
});

export default persistReducer(persistConfig, rootReducer);