import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchOrderRequest = () => ({
    type: actionTypes.FETCH_ORDER_REQUEST,
});
export const fetchOrderSuccess = (data) => ({
    type: actionTypes.FETCH_ORDER_SUCCESS,
    payload: data
});
export const fetchOrderFailure = (error) => ({
    type: actionTypes.FETCH_ORDER_FAILURE,
    payload: error,
});

export const fetchOrderData = (page, limit, stage, search) => {
    return async (dispatch) => {
        dispatch(fetchOrderRequest());
        try {
            const { data } = await api.orders(page, limit, stage, search);
            dispatch(fetchOrderSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchOrderFailure(error));
        }
    };

};

export const addOrderRequest = () => ({
    type: actionTypes.ADD_ORDER_REQUEST,
});
export const addOrderSuccess = (order) => ({
    type: actionTypes.ADD_ORDER_SUCCESS,
    payload: order,
});
export const addOrderFailure = (error) => ({
    type: actionTypes.ADD_ORDER_FAILURE,
    payload: error,
});

export const addOrderData = (orderData) => {
    return async (dispatch) => {
        dispatch(addOrderRequest());
        try {
            const { data } = await api.addOrder(orderData);

            dispatch(addOrderSuccess(data));
            return data;

        } catch (error) {
            dispatch(addOrderFailure(error));
        }
    };
};

export const editOrderRequest = () => ({
    type: actionTypes.EDIT_ORDER_REQUEST,
});
export const editOrderSuccess = (updatedOrder) => ({
    type: actionTypes.EDIT_ORDER_SUCCESS,
    payload: updatedOrder,
});
export const editOrderFailure = (error) => ({
    type: actionTypes.EDIT_ORDER_FAILURE,
    payload: error,
});
export const editOrderData = (orderData) => {
    return async (dispatch) => {
        dispatch(editOrderRequest());
        try {
            const response = await api.editOrder(orderData.order_id, orderData);
            const updatedItem = response.data;
            const successAction = editOrderSuccess(updatedItem);
            dispatch(successAction);
            return successAction;

        } catch (error) {
            dispatch(editOrderFailure(error));
        }
    };
};

export const editMultipleItemRequest = () => ({
    type: actionTypes.EDIT_MULTIPLE_ITEMS_REQUEST,
});
export const editMultipleItemSuccess = (updatedOrder) => ({
    type: actionTypes.EDIT_MULTIPLE_ITEMS_SUCCESS,
    payload: updatedOrder
});
export const editMultipleItemFailure = (error) => ({
    type: actionTypes.EDIT_MULTIPLE_ITEMS_FAILURE,
    payload: error
});

export const editMultipleOrderItemData = (updatedOrder) => {
    console.log("updated Item inside Multiple", updatedOrder);
    return async (dispatch) => {
        dispatch(editMultipleItemRequest());
        try {
            const response = await api.editMultipleItems(updatedOrder.order_items[0].order_id
                , {
                    "file_number": updatedOrder.file_number,
                    "heading": updatedOrder.heading,
                    "completion_date": updatedOrder.completion_date,
                    "comment": updatedOrder.comment,
                    "order_items": updatedOrder.order_items
                });
            const updatedItem = response.data;
            console.log("updated item", response);
            dispatch(editMultipleItemSuccess(updatedItem));
            return updatedItem;
        } catch (error) {
            dispatch(editMultipleItemFailure(error));
        }
    };
};


export const deleteOrderRequest = () => ({
    type: actionTypes.DELETE_ORDER_REQUEST,
});
export const deleteOrderSuccess = (id) => ({
    type: actionTypes.DELETE_ORDER_SUCCESS,
    payload: id,
});
export const deleteOrderFailure = (error) => ({
    type: actionTypes.DELETE_ORDER_FAILURE,
    payload: error
});
export const deleteOrderData = (id) => {
    return async (dispatch) => {
        dispatch(deleteOrderRequest());
        try {
            const { data } = await api.deleteOrder(id);
            console.log(data)
            dispatch(deleteOrderSuccess(id));
            toast.success("Product Deleted Successfully");
        } catch (error) {
            dispatch(deleteOrderFailure(error));
            toast.error("failed to delete product");
        }

    };
};

export const uploadOrderImageRequest = () => ({
    type: actionTypes.UPLOAD_ORDERIMG_REQUEST,
});
export const uploadOrderImageSuccess = (updatedOrder) => ({
    type: actionTypes.UPLOAD_ORDERIMG_SUCCESS,
    payload: updatedOrder,
});
export const uploadOrderImageFailure = (error) => ({
    type: actionTypes.UPLOAD_ORDERIMG_FAILURE,
    payload: error,
});

export const uploadOrderImage = (orderData) => {
    return async (dispatch) => {
        dispatch(uploadOrderImageRequest());
        try {
            const response = await api.imageUpload(orderData.get('order_id'), orderData.get('item_id'), orderData);
            const updatedItem = response.data;
            const successAction = uploadOrderImageSuccess(updatedItem);
            dispatch(successAction);
            return successAction;


        } catch (error) {
            dispatch(uploadOrderImageFailure());
        }
    };

};

export const getSingleOrderRequest = () => ({
    type: actionTypes.GET_SINGLE_ORDER_REQUEST,
});
export const getSingleOrderSuccess = (updatedOrder) => ({
    type: actionTypes.GET_SINGLE_ORDER_SUCCESS,
    payload: updatedOrder,
});
export const getSingleOrderFailure = (error) => ({
    type: actionTypes.GET_SINGLE_ORDER_FAILURE,
    payload: error,
});
export const getSingleOrderData = (orderData) => {

    return async (dispatch) => {
        dispatch(getSingleOrderRequest());
        try {

            const response = await api.getSingleOrder(orderData.order_id);
            const updatedItem = response.data;
            const successAction = getSingleOrderSuccess(updatedItem);
            dispatch(successAction);
            return successAction;

        } catch (error) {
            dispatch(getSingleOrderFailure(error));
        }
    };
};


export const markMistakeOnOrderAction = (order_id, formData) => {
    return async (dispatch) => {
        // dispatch(statusChangeRequest());
        try {
            const response = await api.markMistakeOnOrderApiFn(order_id, formData);
            console.log("response", response)
            const updatedItem = response.data;
            toast.success(updatedItem.message, {
                theme: 'light',
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log("updated Item", updatedItem)
            // dispatch(statusChangeSuccess(updatedItem));
            return updatedItem;
        } catch (error) {
            // dispatch(statusChangeFailure(error));
        }
    };
};


export const triggerNotificationOnOrderAction = (order_id, formData) => {
    return async (dispatch) => {
        try {
            const response = await api.triggerNotificationOnOrderApiFn(order_id, formData);
            console.log("response", response)
            const updatedItem = response.data;
            console.log("updated Item", updatedItem)
            toast.success(updatedItem.message, {
                theme: 'light',
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return updatedItem;
        } catch (error) {
        }
    };
};

