import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, FormControl, Grid, IconButton, InputLabel, ListItemIcon, MenuItem, Select,
  Stack, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getSingleOrderData, editOrderData, fetchOrderData, editMultipleOrderItemData
} from '../../redux/actions/orderActions';
import { fetchProductListData } from '../../redux/actions/productActions';
import CloseIcon from '@mui/icons-material/Close';
import { createMultipleOrderItemData, deleteOrderDetails } from '../../redux/actions/orderDetailsActions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const EditIndividualOrderPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(params.child);
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    setId(params.child);
  }, [params.child]);

  useEffect(() => {
    const fetchSingleOrder = async () => {
      setLoading(true);
      const orderid = { order_id: id };
      await dispatch(getSingleOrderData(orderid));
      await dispatch(fetchProductListData());
      setLoading(false);
    };

    fetchSingleOrder();
  }, [dispatch, id]);

  const [mobileNumber, setMobilenumber] = useState(orders?.data?.associated_customer?.mobile || '');
  const [orderNumber, setOrderNumber] = useState(orders?.data?.order_id || '');
  const [completionDate, setCompletionDate] = useState(dayjs(orders?.data?.completion_date || null));
  const [fileNumber, setFileNumber] = useState(orders?.data?.file_number);
  const [heading, setHeading] = useState(orders?.data?.heading);
  const [comment, setComment] = useState(orders?.data?.comment);
  const [action, setAction] = useState('');


  useEffect(() => {
    if (orders?.data) {
      setOrderItems(
        orders.data?.order_items?.map((item) => ({
          ...item,
          completion_date: dayjs(item.completion_date || null),
          _action: item._action,
        }))
      );

      setFileNumber(orders?.data.file_number);
      setHeading(orders?.data.heading);
      setComment(orders?.data.comment);
      setMobilenumber(orders?.data?.associated_customer?.mobile);
      setOrderNumber(orders?.data.order_id);
      setCompletionDate(dayjs(orders?.data.completion_date || null));
    }
  }, [orders]);

  // add functionality 
  const [orderItems, setOrderItems] = useState([{
    product_id: "", quantity: "", completion_date: '', width: "",
    height: "", comment: "", stage: "", description: "", order_id: Number(id),
    _action: "", item_id: ""
  }]);
  const handleAddItems = () => {
    setOrderItems([...orderItems, {
      product_id: "", quantity: "", completion_date: '', width: "",
      height: "", comment: "", stage: "", description: "", order_id: Number(id),
      _action: "create", item_id: ""
    }])
  };


  const handleItemChange = (index, field, value) => {
    const updatedItems = [...orderItems];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: field === 'completion_date' ? dayjs(value || null) : value,
      ...(field === '_action' && { _action: value }),
    };
    setOrderItems(updatedItems);
  };

  const handleSave = async () => {
    const orderData = {
      order_items: orderItems,
      file_number: fileNumber,
      heading,
      completion_date: completionDate.format(), // Ensure date is properly formatted
      comment,
    };

    try {
      await dispatch(editMultipleOrderItemData(orderData));
      navigate('/order');
    } catch (error) {
      console.error("Failed to save order:", error);
    }
  };

  const handleCancel = () => {
    navigate('/order');
  };



  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleDeleteIconClick = (itemIndex) => {
    let itemId = orderItems[itemIndex].item_id
    console.log("item Id", itemId);
    if (!itemId) {
      console.log("Item Id not found");
      setOrderItems(orderItems.filter((_, i) => i !== itemIndex));
    } else {
      orderItems[itemIndex]._action = "delete"
    }
  };

  const handleDeleteConfirm = async () => {
    const orderData = { order_id: id, item_id: selectedItemId };
    try {
      await dispatch(deleteOrderDetails(orderData));
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Failed to delete order item:", error);
    }
  };

  const [showComment, setShowComment] = useState(false);
  const handleShowComment = () => {
    setShowComment(!showComment);
  }



  return (
    <div>
      <Typography variant='h5' sx={{ textAlign: 'left' }}>
        You are modifing Order Id #{id}
      </Typography>
      <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {orderNumber !== '' ? (
          <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 2, boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: '20px', borderRadius: '16px', marginBottom: '20px', background: 'white'
          }}>


            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
              <TextField label="Order Number" value={orderNumber} InputLabelProps={{ shrink: true }} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Completion Date"
                  value={completionDate}
                  onChange={(newDate) => setCompletionDate(dayjs(newDate || null))}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField label="File Number" value={fileNumber} onChange={(e) => setFileNumber(e.target.value)} InputLabelProps={{ shrink: true }}></TextField>
            </Stack>

            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>


            </Stack>
          </Box>
        ) : (
          <Typography>Waiting for Customer Information</Typography>
        )}

        <Typography>Edit order item</Typography>

        {orderItems && orderItems.length > 0 ? (

          <Grid container spacing={2}>
            {orderItems.map((item, index) => (

              <Grid item xs={12} sm={12} key={index}>
                {item._action !== "delete" &&
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '15px',
                      background: 'white',
                      borderRadius: '16px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      width: '100%'
                    }}
                  >
                    <p
                      style={{ fontSize: 'small', cursor: 'pointer', textAlign: 'left', margin: '0 0', color: 'gray', marginBottom: "5px" }}

                    >
                      {item?.item_id ? <> Item Id #{item?.item_id}</> : "Pending to create"}
                    </p>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        width: '100%',
                        flexWrap: 'wrap'
                      }}
                    >
                      <FormControl sx={{ flex: 1 }}>
                        <InputLabel id={`product-select-label-${index}`}>Product Choose</InputLabel>
                        <Select
                          labelId={`product-select-label-${index}`}
                          id={`product-select-${index}`}
                          value={item.product_id}
                          onChange={(e) => handleItemChange(index, 'product_id', e.target.value)}
                        >
                          {Array.isArray(products?.data) &&
                            products.data.map((product) => (
                              <MenuItem key={product.product_id} value={product.product_id}>
                                {product.product_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <TextField
                        label="Quantity"
                        value={item.quantity}
                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                        sx={{ flex: 1 }}
                      />

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Completion Date"
                          value={dayjs(item.completion_date || null)}
                          onChange={(newDate) => handleItemChange(index, 'completion_date', newDate)}
                          renderInput={(params) => <TextField {...params} fullWidth sx={{ flex: 1 }} />}
                        />
                      </LocalizationProvider>

                      <TextField
                        label="Width"
                        value={item.width}
                        onChange={(e) => handleItemChange(index, 'width', e.target.value)}
                        sx={{ flex: 1 }}
                      />

                      <TextField
                        label="Height"
                        value={item.height}
                        onChange={(e) => handleItemChange(index, 'height', e.target.value)}
                        sx={{ flex: 1 }}
                      />

                      <IconButton
                        sx={{ backgroundColor: '#f44336', width: '40px', height: '40px', color: 'white' }}
                        onClick={() => handleDeleteIconClick(index)}
                      >
                        <DeleteForeverRoundedIcon />
                      </IconButton>
                    </Stack>

                    <p
                      style={{ fontSize: 'small', cursor: 'pointer', textAlign: 'left', margin: '0 0', color: 'blue' }}
                      onClick={handleShowComment}
                    >
                      Show Comment
                    </p>

                    {showComment && (
                      <TextField
                        label="Any Comments"
                        value={item.comment}
                        onChange={(e) => handleItemChange(index, 'comments', e.target.value)}
                        fullWidth
                      />
                    )}
                  </Box>
                }

              </Grid>

            ))}
          </Grid>
        ) : (
          <Typography>No order items found</Typography>
        )}

        <Box>
          <Button variant='contained' onClick={handleAddItems}>Add More Items</Button>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
          <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
        </Box>
      </Stack>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>{"Are you sure you want to delete this order?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this order will remove it permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditIndividualOrderPage;
