import React, { useEffect, useState } from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    Button,
    TableRow,
    TableBody,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    TextField,
    IconButton,
    Box,
    Typography,
    Toolbar,
    Pagination
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, createData, updateData, deleteData } from '../../redux/actions/chargeActions';
import { toast } from 'react-toastify';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

function ChargesCategory() {
    const columns = [
        { id: 'charge_id', name: 'Charge-Id' },
        { id: 'charge_name', name: 'Charge-name' },
        { id: 'description', name: 'Description' },
        { id: 'price', name: 'Price' },
        { id: 'price_unit', name: 'Price-Unit' },
        { id: 'actions', name: 'Actions' }
    ];

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {
        fetchData(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        let rsp = await dispatch(fetchData(value, limit));
    };


    useEffect(() => {
        const fetchChargesData = async () => {
            setLoading(true);
            let rsp = await dispatch(fetchData(page, limit, search));
            console.log("rsp", rsp);
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };

        fetchChargesData();
    }, [dispatch]);

    const refreshButton = async () => {
        setLoading(true);
        await dispatch(fetchData(page, limit, search));
        setLoading(false);
    }
    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    }
    const handleCloseFilter = () => {
        setOpenFilter(false);
    }

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(fetchData(1, limit, search));
        setLoading(false);
    };

    const handleClear = async () => {
        setSearch('');
        setPage(1);
        setLoading(true);
        await dispatch(fetchData(1, limit, ''));
        setLoading(false);
    };

    const data = useSelector((state) => state.charge.data);
    const error = useSelector((state) => state.charge.error);

    const [chargeName, setChargeName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [priceUnit, setPriceUnit] = useState('');
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteChargeId, setDeleteChargeId] = useState(null);

    const [editChargeId, setEditChargeId] = useState(null);
    const [editChargeName, setEditChargeName] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editPrice, setEditPrice] = useState('');
    const [editPriceUnit, setEditPriceUnit] = useState('');

    const functionAdd = () => {
        openpopup();
    };

    const closepopup = () => {
        setOpen(false);
    };

    const openpopup = () => {
        setOpen(true);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newCharge = { charge_name: chargeName, description, price, price_unit: priceUnit };
        const response = await dispatch(createData(newCharge));
        console.log("response :-", response);
        if (response?.type === 'CREATE_DATA_SUCCESS') {
            toast.success('Data created successfully!');
        } else {
            toast.error('Failed to create data');
        }
        await dispatch(fetchData(page, limit, search));
        closepopup();
        setChargeName('');
        setDescription('');
        setPrice('');
        setPriceUnit('');
    };

    const openEditPopup = (charge) => {
        setEditChargeId(charge.charge_id);
        setEditChargeName(charge.charge_name);
        setEditDescription(charge.description);
        setEditPrice(charge.price);
        setEditPriceUnit(charge.price_unit);
        setEditOpen(true);
    };

    const closeEditPopup = () => {
        setEditOpen(false);
    };
    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCharge = { charge_id: editChargeId, charge_name: editChargeName, description: editDescription, price: editPrice, price_unit: editPriceUnit };
        const response = await dispatch(updateData(editChargeId, updatedCharge));
        if (response?.type === 'UPDATE_DATA_SUCCESS') {
            toast.success('Data updated successfully!');
        } else {
            toast.error('Failed to update data');
        }
        await dispatch(fetchData(page, limit, search));
        closeEditPopup();
    };

    const openDeletePopup = (charge_id) => {
        setDeleteChargeId(charge_id);
        setDeleteOpen(true);
    };

    const closeDeletePopup = () => {
        setDeleteOpen(false);
        setDeleteChargeId(null);
    };
    const handleConfirmDelete = async () => {
        if (deleteChargeId) {
            const response = await dispatch(deleteData(deleteChargeId));
            if (response?.type === 'DELETE_DATA_SUCCESS') {
                toast.success('Data deleted successfully!');
            } else {

            }
            await dispatch(fetchData(page, limit, search));
            closeDeletePopup();
        }
    };
    const validateCreateFields = () => {
        return chargeName.length > 0 && chargeName.length <= 50 &&
            price !== '' && isNaN(price) && parseFloat(price) >= 0 && parseFloat(price) <= 10000 &&
            priceUnit !== '' && isNaN(priceUnit) && parseFloat(priceUnit) >= 0 && parseFloat(priceUnit) <= 10000;
    };

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('language') || 'en';
    });
    const headingObj = {
        en: 'Charges',
        hi: 'दाम'
    }
    const addButton = {
        en: 'Add New Charges',
        hi: 'नया दाम जोडे'
    }
    const edit = {
        en: 'Edit',
        hi: 'संशोधित'
    }
    const view = {
        en: 'View',
        hi: 'आलोकन'
    }
    const remove = {
        en: 'Delete',
        hi: 'समाप्त'
    }
    const submit = {
        en: 'Submit',
        hi: 'निवेदन'
    }
    const cancel = {
        en: 'Cancel',
        hi: 'रद्द करें'
    }
    const update = {
        en: 'Update',
        hi: 'संशोधन'
    }
    const removeMsg = {
        hi: 'क्या आप वाकई दाम को हटाना चाहते हैं',
        en: 'Are you sure you want to delete this charges'
    }

    const confirmDelete = {
        en: 'Confirm Delete',
        hi: 'दाम हटाने की पुष्टि'
    }

    return (
        <div>
            <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'flex-start', md: 'space-between' },
                        width: '100%'
                    }}>
                        <Typography sx={{
                            fontSize: { xs: '20px', sm: '24px', md: '28px' },
                            mb: { xs: 2, md: 0 },
                            textAlign: {}
                        }}>
                            {headingObj[language]}</Typography>

                        <Box sx={{
                            display: 'flex',
                            gap: { xs: 1, md: 0.5 },
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            width: { xs: '100%', md: 'auto' },
                            alignItems: 'center'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'row', md: 'row' },
                                justifyContent: { xs: 'flex-start' }
                            }}>
                                <IconButton onClick={handleOpenFilter}>
                                    <FilterAltIcon sx={{ xs: '25px', sm: '30px', md: '35px' }} />
                                </IconButton>

                                <IconButton onClick={refreshButton}>
                                    <RefreshIcon sx={{ xs: '25px', sm: '30px', md: '35px' }} />
                                </IconButton>
                            </Box>
                            <Button variant='contained' sx={{ 
                                backgroundColor: 'midnightblue',
                                textTransform: 'none',
                                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                width: { xs: '100%', sm: 'auto' }
                                }} 
                                onClick={functionAdd}>Add New Charges(+)</Button>
                        </Box>
                    </Box>
                </Toolbar>
            </Box>

            <Paper sx={{ margin: '1%', padding: '1%' }}>

                <div style={{ margin: '1%' }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: 'midnightblue' }}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} style={{ color: 'white' }}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                ) : error ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            Error fetching data
                                        </TableCell>
                                    </TableRow>
                                ) : data && data?.data?.rows?.length > 0 ? (
                                    data.data.rows.map((charge) => (
                                        <TableRow key={charge.charge_id}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.id !== 'actions' ? (
                                                        charge[column.id]
                                                    ) : (
                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                            <Button onClick={() => openEditPopup(charge)} variant='contained' style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }}>Edit</Button>
                                                            <Button onClick={() => openDeletePopup(charge.charge_id)} variant='contained' style={{ backgroundColor: 'red', color: 'white', textTransform: 'none' }}>Delete</Button>
                                                        </div>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>
                </div>
            </Paper>
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{addButton[language]}</Typography>
                    </DialogTitle>
                    <IconButton onClick={closepopup} >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} margin={2}>
                            <TextField required error={chargeName.length > 50} value={chargeName} onChange={e => setChargeName(e.target.value)} variant='outlined' label="Charge-Name" />
                            <TextField error={description.length > 100} value={description} onChange={e => setDescription(e.target.value)} variant='outlined' label="Description" />
                            <TextField required error={isNaN(price) || parseFloat(price) < 0 || parseFloat(price) > 10000} value={price} onChange={e => setPrice(e.target.value)} variant='outlined' label="Price" />
                            <TextField required error={isNaN(priceUnit) || parseFloat(priceUnit) < 0 || parseFloat(priceUnit) > 10000} value={priceUnit} onChange={e => setPriceUnit(e.target.value)} variant='outlined' label="Price-Unit" />
                            <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20, justifyContent: 'center' }} >
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closepopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} disabled={!validateCreateFields()} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{edit[language]} {headingObj[language]}</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleEditSubmit}>
                        <Stack spacing={2} margin={2}>
                            <TextField required error={editChargeName.length > 50} value={editChargeName} onChange={e => setEditChargeName(e.target.value)} variant='outlined' label="Charge-Name" />
                            <TextField error={editDescription.length > 100} value={editDescription} onChange={e => setEditDescription(e.target.value)} variant='outlined' label="Description" />
                            <TextField required error={isNaN(editPrice) || parseFloat(editPrice) < 0 || parseFloat(editPrice) > 10000} value={editPrice} onChange={e => setEditPrice(e.target.value)} variant='outlined' label="Price" />
                            <TextField required error={isNaN(editPriceUnit) || parseFloat(editPriceUnit) < 0 || parseFloat(editPriceUnit) > 10000} value={editPriceUnit} onChange={e => setEditPriceUnit(e.target.value)} variant='outlined' label="Price-Unit" />
                            <Stack spacing={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, justifyContent: 'center' }} >
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} disabled={!validateCreateFields()} type='submit'>Update</Button>
                            </Stack>

                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>{confirmDelete[language]}</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography>{removeMsg[language]}?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeDeletePopup} color="primary">
                        Cancel
                    </Button>
                    <Button variant='contained' sx={{ textTransform: 'none' }} onClick={handleConfirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openFilter} onClose={handleCloseFilter}>
                <DialogTitle>Filter Customers</DialogTitle>
                <DialogContent>
                    <TextField label="Search by name, mobile, or address" fullWidth value={search} onChange={(e) => setSearch(e.target.value)} variant="outlined" margin="normal" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClear} color="primary">
                        Clear
                    </Button>
                    <Button onClick={handleSearch} color="primary">
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChargesCategory;
