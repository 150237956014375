import { Avatar, Box, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react'
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import { useNavigate } from 'react-router-dom';

function MenuLinks() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const [projects, setProjects] = useState([
        { name: "CRM App", avatar: "https://via.placeholder.com/100", path: "https://crm.rajoffset.com/" },
        { name: "Internal Portal", avatar: "https://via.placeholder.com/100", path: "https://internal-portal.rajoffset.com/" },
        { name: "Digital Invitation", avatar: "https://via.placeholder.com/100", path: "https://app.rajoffset.com/" },
        { name: "RajOffset Web Application", avatar: "https://via.placeholder.com/100", path: "https://rajoffset.com/" },
        { name: "RajOffset Alpha Application", avatar: "https://via.placeholder.com/100", path: "https://alpha.rajoffset.com/" },

    ]);

    const navigate = useNavigate();
    const handleNavigation = (path_url) => {
        console.log("path url", path_url);
        window.open(path_url, '_blank');
    };
    return (
        <div>
            <IconButton
                aria-controls="action-menu"
                aria-haspopup="true"

                onClick={(event) => handleClick(event)}
            >
                <AppsRoundedIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxWidth: '400px',
                        width: '100%',
                        maxHeight: '300px',
                        overflowY: 'auto',
                    },
                }}
            >
                <Box sx={{ padding: '10px' }}>
                    <Grid container spacing={1}>
                        {projects.map((project, index) => (
                            <Grid item key={index} xs={4} >
                                <MenuItem onClick={() => handleNavigation(project.path)} sx={{ padding: '0px', display: 'flex', justifyContent: 'center' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            m: 1,
                                        }}
                                    >
                                        <Avatar
                                            src={project.avatar}
                                            alt={project.name}
                                            sx={{ width: 60, height: 60 }}
                                        />
                                        <Typography sx={{
                                            mt: 1,
                                            fontSize: '0.9rem',
                                            lineHeight: 1.2,   
                                            maxWidth: 80,       
                                            wordWrap: 'break-word', 
                                            whiteSpace: 'normal', 
                                        }} >
                                            {project.name}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Menu>

        </div>
    )
}

export default MenuLinks